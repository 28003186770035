@import "../var.scss";

.main__footer {
    height: 100vh;

    &-phone {
        position: absolute;
        top: 129px;
        width: 954px;
        height: 320px;
        left: -337px;
        &-wrap {
            position: relative;
            &-body {
                border-radius: 30px;
                background: $gradient1;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }
    &-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #ffffff;
        padding: 50px 57px 0 50px;
        position: relative;
        .main--uptitle{
            position: absolute;
            top: -50px;
            left: 0;
        }
        h3 {
            font-size: 30px;
            font-style: normal;
            font-weight: 700;
            line-height: 50px; /* 166.667% */
            letter-spacing: 1.5px;
        }
        p {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 171.429% */
            letter-spacing: 0.7px;
        }

        &-market {
            position: relative;
        }
        &-lable {
            margin-top: 10px;
            display: inline-flex;
        }
        &-left {
            position: absolute;
            top: 99%;
            left: 16%;
        }
        &-right {
            position: absolute;
            top: 99%;
            left: 69%;
        }
    }
}


.main__footer-button:hover{
    position: relative;
    overflow: hidden;
    &:before {
        content: "";
        position: absolute;
        height: 150px;
        width: 50px;
        background: #ffffff53;
        left: -50px;
        top: -40px;
        transform: rotate(37deg);
        animation: moveRight 2s ease infinite;
        opacity: 0.3;
    }
    @keyframes moveRight {
        0% {
            left: -50px;
        }
        100% {
            left: 105%;
        }
    }
}