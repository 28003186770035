@import "../var.scss";

.main__categories {
    z-index: 3;
    &--body {
        display: flex;
        &-1 {
            flex: 1;
            position: relative;
            min-width: 604px;
        }
        &-2 {
            width: 430px;
        }
    }
    &-title {
        padding-bottom: 279px;
        padding-left: 57px;
        display: flex;
        flex-direction: column;
        width: fit-content;
        white-space: nowrap;
        &-text {
            width: fit-content;
            &-1 {
                font-size: 1.8em;
            }
            &-2 {
                font-size: 2.2em;
            }
        }
    }

    &--general {
        position: absolute;
        background-repeat: no-repeat;
        background-size: contain;
    }
    &-parag {
        padding-top: 24px;
        padding-left: 57px;
        font-weight: 400;
        line-height: 24px; /* 171.429% */
        letter-spacing: 0.7px;
        opacity: 0.7;
        width: 308px;
    }
    &-1 {
        background-image: url("../../../public/Images/num1.png");
        width: 201px;
        height: 204px;
        top: 216px;
        left: 10%;
    }
    &-2 {
        background-image: url("../../../public/Images/num2.png");
        width: 146px;
        height: 151px;
        top: 130px;
        left: 44%;
    }
    &-3 {
        background-image: url("../../../public/Images/num3.png");
        width: 248px;
        height: 230px;
        top: 50px;
        left: 64%;
    }
    &-4 {
        background-image: url("../../../public/Images/num4.png");
        width: 166px;
        height: 195px;
        top: 300px;
        left: 40%;
    }
    &-5 {
        background-image: url("../../../public/Images/num5.png");
        width: 160px;
        height: 177px;
        top: 280px;
        left: 70%;
    }
}

@media only screen and (max-width: 1080px) {
    .main {
        &__categories {
            &-title {
                padding-left: 0;

                &-text {
                    line-height: 45px;
                    letter-spacing: 2px;
                    width: fit-content;
                    &-1 {
                        font-size: 1.6em;
                    }
                    &-2 {
                        font-size: 2em;
                    }
                }
            }
            &--body-1 {
                max-width: 100vw;
            }
            &--body-2 {
                width: 0px;
            }
            &-parag {
                padding: 51px 0 0 0;
                max-width: 100vw;
                width: 100%;
            }
        }
    }
}

@media only screen and (max-width: 665px) {
    .main__categories {
        &-title {
            &-text {
                &-1 {
                    font-size: 1.4em; // Еще меньше для мобильных
                }

                &-2 {
                    font-size: 1.8em; // Уменьшен второй заголовок
                }
            }
        }
        &-1 {
            display: none;
        }
        &-2 {
            width: 99px;
            height: 113px;
            top: 190px;
            left: 3%
        }
        &-3 {
            width: 175px;
            height: 163px;
            top: 150px;
            left: 20%;
        }
        &-4 {
            width: 117px;
            height: 130px;
            top: 314px;
            left: 4%;
        
        }
        &-5 {
            width: 111px;
            height: 125px;
            top: 298px;
            left: 26%;
        }
    }
}


@keyframes show__image{
    from {
        opacity: 0;
        transform: translate(0px, 20px);
    }
    to {
        opacity: 1;
        transform: translate(0px, 0px);
    }
}

.show__image{
    animation: show__image 2s ease forwards;
}
